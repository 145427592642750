import { IPost } from '@/shared/api/getBlog/types';
import { Button } from '@/shared/ui/Button';
import { LazyImage } from '@/shared/ui/LazyImage';
import { Link } from '@/shared/ui/Link';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { cva } from 'class-variance-authority';
import { format, parse } from 'date-fns';
import { ru } from 'date-fns/locale';
import { FC } from 'react';
import ArrowRightIcon from '~/icons/arrow-right.svg';

interface Props {
  className?: string;
  data: IPost;
}

export const ArticlePreviewCard: FC<Props> = ({ className, data }) => {
  const { name, date, preview_picture, link, author } = data;

  const parsedDate = parse(date, 'dd.MM.yyyy', new Date());
  const formattedDate = format(parsedDate, 'dd MMM`yy', { locale: ru });
  const [day, monthYear] = formattedDate.split(' ');

  return (
    <Link
      className={twMergeExt(className, 'w-full')}
      contentWrapperClassName={cvaRoot()}
      animateWrapperClassName="w-full"
      href={link}
    >
      <div className={twMergeExt(cvaDateContainer(), 'hidden lg:flex')}>
        <div className={cvaDay()}>{day}</div>
        <div className={cvaDate()}>{monthYear.replace('.', '')}</div>
      </div>

      <div className="w-fit h-fit overflow-hidden rounded-[1.6rem] lg:ml-4.2">
        <div className={cvaImageWrapper()}>
          {preview_picture ? (
            <LazyImage className={'object-center object-cover'} src={preview_picture} alt={name ?? ''} fill />
          ) : null}
        </div>
      </div>

      <div className={cvaLeftContainer()}>
        <div className={cvaLeftTopContainer()}>
          <div className={cvaTitle()}>
            <div className={twMergeExt(cvaDateContainer(), 'flex lg:hidden')}>
              <div className={cvaDay()}>{day}</div>
              <div className={cvaDate()}>{monthYear}</div>
            </div>
            <div>{name}</div>
          </div>
          <div className={cvaTags()}>
            <div className={cvaTag()}>Блог</div>
          </div>
        </div>

        <div className={cvaLeftBottomContainer()}>
          <div>
            {author.name ? (
              <div className={cvaAuthor()}>
                <div className={cvaAuthorPhoto()}>
                  {author.photo ? (
                    <LazyImage className={'object-center object-cover'} src={author.photo} alt={author.name} fill />
                  ) : (
                    <LazyImage
                      className={'object-center object-cover'}
                      src={'/icons/authro_no_photo.svg'}
                      alt={author.name}
                      fill
                    />
                  )}
                </div>
                <div className={cvaAuthorNameContainer()}>
                  <div className={cvaAuthorName()}>{author.name}</div>
                  {author.position ? <div className={cvaAuthorDescr()}>{author.position}</div> : null}
                </div>
              </div>
            ) : (
              <div className={cvaAuthor()}>
                <div className={cvaAuthorPhoto()}>
                  <LazyImage
                    className={'object-center object-cover'}
                    src={'/icons/aloe_avatar.png'}
                    alt={'aloe'}
                    fill
                  />
                </div>
                <div className={cvaAuthorNameContainer()}>
                  <div className={cvaAuthorName()}>Aloe Smart</div>

                  <div className={cvaAuthorDescr()}>Команда Aloe Smart</div>
                </div>
              </div>
            )}
          </div>

          <div className={cvaButtonWrapper()}>
            Читать статью
            <Button className={cvaLinkButton()} color={'darkGreyOnGrey'} size={'medium'} hoverEffect={'highlight'}>
              <ArrowRightIcon className={'w-1.6 h-1.6'} />
            </Button>
          </div>
        </div>
      </div>
    </Link>
  );
};

const cvaRoot = cva([
  'ArticlePreviewCard-cvaRoot group',
  'flex flex-wrap',
  'p-2 rounded-[2rem] sm:p-3.2 sm:rounded-[3.2rem]',
  'bg-cLightGrey',
]);

const cvaDateContainer = cva(['ArticlePreviewCard-cvaDateContainer', 'flex flex-col items-center']);

const cvaDay = cva(['ArticlePreviewCard-cvaDay', 'text-factoidL']);

const cvaDate = cva(['ArticlePreviewCard-cvaDate', 'sm:mt-0.4', 'text-bodyM text-cBlack64']);

const cvaImageWrapper = cva([
  'ArticlePreviewCard-cvaImageWrapper',
  'relative',
  'aspect-[27.2/15.7] lg:aspect-[50.5/29.2] w-[27.2rem] sm:w-[52rem] lg:w-[50.5rem] overflow-hidden',
  'rounded-[1.6rem] group-hover:scale-[1.05] transition-all duration-300',
]);

const cvaLeftContainer = cva([
  'ArticlePreviewCard-cvaLeftContainer',
  'flex flex-row flex-wrap lg:flex-col justify-between gap-1.5 sm:gap-1.6 sm:gap-2.4 lg:gap-3.2',
  'flex-1 basis-full lg:basis-0',
  'mt-2.4 sm:mt-3.2 lg:mt-0 lg:ml-7',
]);

const cvaLeftTopContainer = cva([
  'ArticlePreviewCard-cvaLeftTopContainer',
  'flex flex-col gap-1.6 sm:gap-2.4 md:gap-3.2 md:flex-row lg:gap-[14.7rem]',
]);

const cvaTitle = cva(['ArticlePreviewCard-cvaTitle', 'flex gap-1.7 sm:gap-2.4', 'flex-1', 'text-titleS']);

const cvaTags = cva([
  'ArticlePreviewCard-cvaTags',
  'flex flex-wrap items-start gap-0.4',
  'ml-5.9 sm:ml-5.8 sm:ml-8.4 md:ml-0',
]);

const cvaTag = cva([
  'ArticlePreviewCard-cvaTag',
  'flex justify-center items-center',
  'h-2.1 sm:h-2.4',
  'px-0.8 sm:px-1.2 rounded-[0.8rem] border border-cMediumGreen',
  'text-cMediumGreen text-bodyS hover:text-cWhite',
  'bg-cWhite hover:bg-cMediumGreen duration-300 transition-all',
]);

const cvaLeftBottomContainer = cva([
  'ArticlePreviewCard-cvaLeftBottomContainer',
  ' flex justify-between gap-4',
  ' basis-full lg:basis-auto',
  ' pt-1.6 sm:pt-3.2 border-t border-t-cBlack16',
]);

const cvaAuthor = cva(['ArticlePreviewCard-cvaAuthor', 'flex']);

const cvaAuthorPhoto = cva([
  'ArticlePreviewCard-cvaAuthorPhoto',
  'relative',
  'w-4 h-4 sm:w-5.6 sm:h-5.6 shrink-0 grow-0 overflow-hidden',
  'rounded-full ',
]);

const cvaAuthorNameContainer = cva([
  'ArticlePreviewCard-cvaAuthorNameContainer',
  'ml-2 sm:ml-1.6 mt-[0.15rem] sm:mt-[0.35rem]',
]);

const cvaAuthorName = cva(['ArticlePreviewCard-cvaAuthorName', 'text-bodyMSemibold text-cBlack']);

const cvaAuthorDescr = cva(['ArticlePreviewCard-cvaAuthorDescr', 'sm:mt-0.4', 'text-bodyS text-cBlack64']);

const cvaButtonWrapper = cva([
  'ArticlePreviewCard-cvaButtonWrapper',
  'hidden sm:flex items-center',
  'text-bodyMSemibold',
]);

const cvaLinkButton = cva([
  'ArticlePreviewCard-cvaLinkButton',
  'ml-1.6 p-2',
  'transition-colors',
  'group-hover:before:translate-y-0 group-hover:before:scale-[1.2] group-hover:before:transition-transform group-hover:before:duration-400 group-hover:before:ease-button-before',
  'group-hover:after:translate-y-0 group-hover:after:[transition-duration:0.05s] group-hover:after:[transition-delay:0.4s] group-hover:after:ease-button-after',
]);
