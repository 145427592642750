'use client';
// import { AnimatedTranslateWrapper } from '@/shared/ui/AnimatedTranslateWrapper';
import { Container } from '@/shared/ui/Container';
import { Title } from '@/shared/ui/Title';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { AboutFeatures } from '@/widgets/about-section/ui/AboutFeatures';
import { cva } from 'class-variance-authority';
import { FC } from 'react';
import { useInView } from 'react-intersection-observer';

interface Props {
  className?: string;
}

export const AboutSection: FC<Props> = ({ className }) => {
  // const ref = useRef<HTMLDivElement>(null);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  return (
    <div ref={ref} className={twMergeExt(cvaRoot(), className)}>
      <Container variant={'small'}>
        <Title className={cvaHeading()} as={'h1'}>
          <span className={'text-cMediumGreen'}>     Aloe Smart —</span> сеть магазинов уходовой и корейской косметики
        </Title>
        <div className={cvaDescr()}>Мы продаем только 100% оригинальную и сертифицированную продукцию.</div>
      </Container>
      <AboutFeatures className={cvaAboutFeatures()} variant={'col'} trigger={inView} />
    </div>
  );
};

const cvaRoot = cva(['AboutSection-cvaRoot', 'overflow-hidden', 'my-6.4 sm:my-[10.4rem]']);

const cvaHeading = cva(['AboutSection-cvaHeading', 'max-w-[86.8rem]', 'mx-auto', 'font-secondary text-h1 text-center']);

const cvaDescr = cva([
  'AboutSection-cvaDescr',
  'max-w-[31.2rem] sm:max-w-[40.6rem]',
  'mx-auto mt-2.4 sm:mt-4.8',
  'text-bodyL text-cBlack80 text-center',
]);

const cvaAboutFeatures = cva(['AboutSection-cvaAboutFeatures', 'mt-3.2 sm:mt-[10.4rem]']);
